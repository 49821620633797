import React from 'react'
import Layout from '../components/layout'
import { graphql, StaticQuery, Link } from 'gatsby'
import HeroGallery from '../components/hero-gallery'
import Footer from '../components/footer'
import HomeContact from '../components/HomeContact'

export default () => (
	<StaticQuery
		query={graphql`
			{
				allWordpressPage(filter: { title: { eq: "Home" } }) {
					edges {
						node {
							acf {
								hero {
									source_url
								}
								intro_statement
								intro_links {
									intro_link {
										title
										url
									}
								}
								top_featured_images {
									image {
										source_url
									}
									link {
										title
										url
									}
								}
								bottom_featured_images {
									image {
										source_url
									}
									link {
										title
										url
									}
								}
								blurb_title
								blurb_subtitle
								blurb_subtitle_two
								blurb_bio
								blurb_image {
									source_url
								}
							}
						}
					}
				}
			}
		`}
		render={(props) => (
			<Layout>
				<div className='home-body'>
					<HeroGallery />
					<div className='home-blurb-container'>
						<div className='home-blurb container'>
							<div className='column is-12-fullhd is-offset-0-fullhd'>
								<img
									src={
										props.allWordpressPage.edges[0].node.acf.blurb_image
											.source_url
									}
									alt='Blurb'
								/>
								<h1>{props.allWordpressPage.edges[0].node.acf.blurb_title}</h1>
								<h2>
									{props.allWordpressPage.edges[0].node.acf.blurb_subtitle}
								</h2>
								<h2>
									{props.allWordpressPage.edges[0].node.acf.blurb_subtitle_two}
								</h2>
								<hr />
								<p>{props.allWordpressPage.edges[0].node.acf.blurb_bio}</p>
							</div>
						</div>
					</div>
					<div className='home-intro container'>
						<div className='home-intro-section column is-10-widescreen is-offset-1-widescreen'>
							<h1>
								{props.allWordpressPage.edges[0].node.acf.intro_statement}
							</h1>
							<div className='home-intro-link-section columns'>
								{props.allWordpressPage.edges[0].node.acf.intro_links.map(
									(item) => (
										<Link
											to={`${item.intro_link.url}`}
											className='column is-3-mobile'>
											{item.intro_link.title}
										</Link>
									)
								)}
							</div>
						</div>
					</div>

					{/* Top Row */}
					<div className='home-feature'>
						<div className='home-featured-image-section columns'>
							{props.allWordpressPage.edges[0].node.acf.top_featured_images.map(
								(item) => (
									<div className='column'>
										<Link to={`${item.link.url}`}>
											<div className='featured-image'>
												<img src={item.image.source_url} alt='Featured' />
												<div className='featured-image-title'>
													{item.link.title}
												</div>
											</div>
										</Link>
									</div>
								)
							)}
						</div>
					</div>

					{/* Bottom Row */}
					<div className='home-feature'>
						<div className='home-featured-image-section columns'>
							{props.allWordpressPage.edges[0].node.acf.bottom_featured_images.map(
								(item) => (
									<div className='column'>
										<Link to={`${item.link.url}`}>
											<div className='featured-image'>
												<img src={item.image.source_url} alt='Featured' />
												<div className='featured-image-title'>
													{item.link.title}
												</div>
											</div>
										</Link>
									</div>
								)
							)}
						</div>
					</div>
				</div>
				{/* Home Contact */}
				<div className='home-contact-container'>
					<HomeContact />
				</div>
				<Footer />
			</Layout>
		)}
	/>
)
