import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import 'bulma-scss'
import Flickity from 'react-flickity-component'

const flickityOptions = {
	initialIndex: 0,
	autoPlay: 4500,
	pauseAutoPlayOnHover: true,
	accessibility: true,
	wrapAround: true,
	prevNextButtons: false,
}

const HeroGallery = () => (
	<StaticQuery
		query={graphql`
			{
				allWordpressPage(filter: { title: { eq: "Home" } }) {
					edges {
						node {
							acf {
								hero {
									source_url
								}
							}
						}
					}
				}
			}
		`}
		render={(props) => (
			<Flickity
				className={'carousel'} // default ''
				elementType={'div'} // default 'div'
				options={flickityOptions} // takes flickity options {}
				disableImagesLoaded={false} // default false
				wrapAround={true}
				reloadOnUpdate // default false
				static // default false
			>
				{props.allWordpressPage.edges[0].node.acf.hero.map((item) => (
					<div className='carousel-cell'>
						<img src={item.source_url} alt='Hero' />
					</div>
				))}
			</Flickity>
		)}
	/>
)

export default HeroGallery
