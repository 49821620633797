import React from 'react'

const HomeContact = () => (
	<div className='contact-body home-contact'>
		<div className='columns'>
			<div className='column is-4-desktop is-offset-4-desktop is-6-tablet is-offset-3-tablet is-12-mobile is-offset-0-mobile contact-form'>
				<form name='contact' netlify netlify-honeypot='bot-field' hidden>
					<input type='text' name='name' />
					<input type='email' name='email' />
					<select name='How did you hear about Allison Liffman Photography?[]'>
						<option value='Select'>Select One</option>
						<option value='Instagram'>Instagram</option>
						<option value='Facebook'>Facebook</option>
						<option value='Web Search'>Web search </option>
						<option value='Family Friend'>Family / Friend</option>
						<option value='Mailer'>Mailer</option>
						<option value='Other'>Other</option>
					</select>
					<textarea name='message'></textarea>
				</form>
				<form name='contact' method='POST' data-netlify='true' netlify>
					<h1>I'd love to hear from you!</h1>
					<input type='hidden' name='form-name' value='contact' />
					<p>
						<label>
							Your Name: <input type='text' name='name' />
						</label>
					</p>
					<p>
						<label>
							Your Email: <input type='email' name='email' />
						</label>
					</p>
					<p>
						<label>
							Message: <textarea name='message'></textarea>
						</label>
					</p>
					<p>
						<label>
							How did you hear about Allison Liffman Photography?
							<select name='How did you hear about Allison Liffman Photography?[]'>
								<option value='Select'>Select One</option>
								<option value='Instagram'>Instagram</option>
								<option value='Facebook'>Facebook</option>
								<option value='Web Search '>Web search </option>
								<option value='Family Friend'>Family / Friend</option>
								<option value='Mailer'>Mailer</option>
								<option value='Other'>Other</option>
							</select>
						</label>
					</p>
					<p>
						<button type='submit'>Send</button>
					</p>
				</form>
			</div>
		</div>
	</div>
)

export default HomeContact
